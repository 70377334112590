import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";
import "./MaintenanceList.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addDays, startOfWeek, startOfMonth, endOfDay, endOfWeek, endOfMonth } from "date-fns";
import * as XLSX from "xlsx"; // Import xlsx for Excel export
import { saveAs } from "file-saver"; // To trigger download of Excel file

const MaintenanceList = () => {
    const [profiles, setProfiles] = useState([]);
    const [equipments, setEquipments] = useState([]);
    const [startDateTime, setStartDateTime] = useState(null);
    const [endDateTime, setEndDateTime] = useState(null);

    useEffect(() => {
        fetchEquipments();
    }, []);

    const fetchProfiles = async () => {
        try {
            let url = '/api/profiles/get_maintenance_profiles_by_equipment/';
            if (startDateTime && endDateTime) {
                const params = `?start_datetime=${startDateTime.toISOString()}&end_datetime=${endDateTime.toISOString()}`;
                url += params;
            }
            const response = await apiConfig.get(url);
            setProfiles(response.data);
        } catch (error) {
            console.error('Error fetching profiles', error);
        }
    };

    const fetchEquipments = async () => {
        try {
            const response = await apiConfig.get('/api/equipments/');
            setEquipments(response.data);
        } catch (error) {
            console.error('Error fetching equipments', error);
        }
    };

    useEffect(() => {
        const fetchProfilesInterval = setInterval(fetchProfiles, 1000);
        return () => {
            clearInterval(fetchProfilesInterval);
        };
    }, [startDateTime, endDateTime]);

    const setToday = () => {
        const now = new Date();
        setStartDateTime(new Date(now.setHours(0, 0, 0, 0)));
        setEndDateTime(endOfDay(now));
    };

    const setThisWeek = () => {
        const now = new Date();
        setStartDateTime(startOfWeek(now, { weekStartsOn: 1 }));
        setEndDateTime(endOfWeek(now, { weekStartsOn: 1 }));
    };

    const setThisMonth = () => {
        const now = new Date();
        setStartDateTime(startOfMonth(now));
        setEndDateTime(endOfMonth(now));
    };

    const formatTime = (totalSeconds) => {
        if (totalSeconds == null) return '';
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = Math.floor(totalSeconds % 60);
        return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${seconds}s`.trim();
    };

    const calculateTotalTimePerEquipment = (equipmentId) => {
        return profiles.reduce((total, profile) => {
            const timeSpentOnEquipment = profile.equipment_data[equipmentId] || 0;
            return total + timeSpentOnEquipment;
        }, 0);
    };

    const calculateGrandTotalTime = () => {
        return equipments.reduce((grandTotal, equipment) => {
            return grandTotal + calculateTotalTimePerEquipment(equipment.id);
        }, 0);
    };

    // Function to download the Excel file
    const downloadExcel = () => {
        const tableData = [];

        // Add headers
        const headers = ["ID", "Equipo", "Estación", "Mandante", "Comentario"];
        profiles.forEach((profile) => {
            headers.push(profile.profile.username);
        });
        headers.push("Total Time Spent");
        tableData.push(headers);

        // Add data for each equipment
        equipments.forEach((equipment) => {
            const row = [
                equipment.id,
                equipment.name,
                equipment.stage.name,
                equipment.last_checkpoint_description === "reparar"
                    ? equipment.last_checkpoint_caller
                    : "",
                equipment.last_checkpoint_description === "reparar"
                    ? equipment.last_checkpoint_comment
                    : "",
            ];

            profiles.forEach((profile) => {
                row.push(formatTime(profile.equipment_data[equipment.id]) || "N/A");
            });

            row.push(formatTime(calculateTotalTimePerEquipment(equipment.id)));
            tableData.push(row);
        });

        // Add total row
        const totalRow = ["", "", "", "", "Grand Total"];
        profiles.forEach((profile) => {
            totalRow.push(formatTime(profile.total_time) || "N/A");
        });
        totalRow.push(formatTime(calculateGrandTotalTime()));
        tableData.push(totalRow);

        // Create a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(tableData);

        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Maintenance Data");

        // Convert the workbook to a blob and trigger the download
        const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
        const blob = new Blob([excelBuffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        saveAs(blob, `MaintenanceData_${startDateTime?.toISOString()}_${endDateTime?.toISOString()}.xlsx`);
    };

    return (
        <div className="table-container">
            <div className="datetime-picker-container">
                <label>Fecha Inicio: </label>
                <DatePicker 
                    selected={startDateTime} 
                    onChange={(date) => setStartDateTime(date)} 
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={60}
                    dateFormat="yyyy-MM-dd HH:mm"
                    placeholderText="Select start datetime"
                />
                <label>Fecha Fin: </label>
                <DatePicker 
                    selected={endDateTime} 
                    onChange={(date) => setEndDateTime(date)} 
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={60}
                    dateFormat="yyyy-MM-dd HH:mm"
                    placeholderText="Select end datetime"
                />
                <div className="buttons-container">
                    <button onClick={setToday}>Hoy</button>
                    <button onClick={setThisWeek}>Esta Semana</button>
                    <button onClick={setThisMonth}>Este Mes</button>
                </div>
            </div>
            
            <button onClick={downloadExcel} className="download-button">
                Descargar Excel
            </button>

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Equipo</th>
                        <th>Estación</th>
                        {profiles && profiles.map((profile, index) => (
                            <th key={index}>{profile.profile.username}</th>
                        ))}
                        <th>Total</th>
                        <th>Mandante</th>
                        <th>Comentario</th>
                    </tr>
                </thead>
                <tbody>
                    {equipments && equipments.map((equipment, index) => {
                        const totalTimeOnEquipment = calculateTotalTimePerEquipment(equipment.id);
                        return (
                            <tr key={index}>
                                <td>{equipment.id}</td>
                                <td style={{ backgroundColor: equipment.last_checkpoint_description === 'reparar' ? 'red' : 'transparent' }}>
                                    {equipment.name}
                                </td>
                                <td>{equipment.stage.name}</td>
                                {profiles && profiles.map((profile) => (
                                    <td key={profile.id} style={{ backgroundColor: profile.working_in === equipment.id ? 'yellow' : 'transparent' }}>
                                        {formatTime(profile.equipment_data[equipment.id]) || 'N/A'}
                                    </td>
                                ))}
                                <td>{formatTime(totalTimeOnEquipment)}</td>
                                <td>{equipment.last_checkpoint_description === 'reparar' ? equipment.last_checkpoint_caller : null}</td>
                                <td>{equipment.last_checkpoint_description === 'reparar' ? equipment.last_checkpoint_comment : null}</td>
                            </tr>
                        );
                    })}

                    <tr className="bold-row">
                        <td colSpan={3}>Total</td>
                        {profiles && profiles.map((profile) => (
                            <td key={profile.id}>{formatTime(profile.total_time) || 'N/A'}</td>
                        ))}
                        <td>{formatTime(calculateGrandTotalTime())}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default MaintenanceList;