import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";

import "./styles.css";
import routes from "../../routes";

const StageList = () => {
    const [stages, setStages] = useState([]);

    useEffect(() => {
        fetchStages();
    }, []);

    const fetchStages = async () => {
        try {
            const response = await apiConfig.get('/api/stages/get_stages_with_last_truck_checkpoint/');
            setStages(response.data);
            console.log('Stages fetched correctly');
        } catch (error) {
            console.error('Error fetching stages', error);
        }
    };

    useEffect(() => {
        const interval = setInterval(fetchStages, 1000); // Poll every 5 seconds
        return () => clearInterval(interval); // Clean up on unmount
    }, []);

    const formatElapsedTime = (dateCreated) => {
        if (!dateCreated) return 'N/A';
        const startTime = new Date(dateCreated);
        const now = new Date();
        const diff = now - startTime; // difference in milliseconds

        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    const isUnderTenMinutes = (dateCreated) => {
        if (!dateCreated) return false;
        const startTime = new Date(dateCreated);
        const now = new Date();
        const diff = now - startTime; // difference in milliseconds

        return diff < 10 * 60 * 1000; // Check if the difference is under 1 minute
    };

    const isOverThirtyMinute = (dateCreated) => {
        if (!dateCreated) return false;
        const startTime = new Date(dateCreated);
        const now = new Date();
        const diff = now - startTime; // difference in milliseconds

        return diff > 30 * 60 * 1000; // Check if the difference is greater than 10 minutes
    };


    return (
            <div className="map-container">
                {stages.length > 0 ?
                    <ul>
                        {stages.map((stage) => (
                            <div
                                key={stage.stage.id}
                                className="stage-marker"
                                style={{
                                    top: `${stage.stage.pos_y}%`,
                                    left: `${stage.stage.pos_x}%`,
                                }}
                            >
                                {
                                    <li>
                                        <h1>
                                            {stage.stage.name}
                                        </h1>
                                        {
                                            stage.last_truck_checkpoints && stage.last_truck_checkpoints.length > 0 ?
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Camion</th>
                                                            <th>Tiempo</th>
                                                            {/* <th>Descripción</th> */}
                                                            {/* <th>Carga</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {stage.last_truck_checkpoints.map((last_truck_checkpoint) => (
                                                            <tr key={last_truck_checkpoint.id}
                                                                className={`${isUnderTenMinutes(last_truck_checkpoint.datetime_created) ? 'blink-background-green' : ''} ${isOverThirtyMinute(last_truck_checkpoint.datetime_created) ? 'blink-background-red' : ''}`}
                                                            >
                                                                <td><a href={`${routes.TRUCK_LIST}/${last_truck_checkpoint.truck.id}`}>{last_truck_checkpoint.truck.plate}</a></td>
                                                                <td>{formatElapsedTime(last_truck_checkpoint.datetime_created)}</td>
                                                                {/* <td>{last_truck_checkpoint.description}</td> */}
                                                                {/* <td>{last_truck_checkpoint.waste_type}</td> */}
                                                            </tr>
                                                        ))
                                                        }
                                                    </tbody>
                                                </table>
                                                :
                                                <p>No hay camiones...</p>
                                        }
                                    </li>
                                }
                            </div>
                        ))
                        }
                    </ul >
                    :
                    <>
                    </>
                }
            </div>
    );
};

export default StageList;