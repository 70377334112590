import React, { Component } from "react";
import { Navigate } from 'react-router-dom';
import routes from "../../routes";
import apiConfig from "../../ApiConfig";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            isLoggedIn: localStorage.getItem('isLoggedIn'),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    async handleSubmit(event) {
        event.preventDefault();
        try {
            const response = await apiConfig.post(
                '/auth/jwt/create/',
                {
                    username: this.state.username,
                    password: this.state.password
                });
            apiConfig.defaults.headers['Authorization'] = 'JWT ' + response.data.access;
            this.setState({ isLoggedIn: true });
            localStorage.setItem('access_token', 'JWT ' + response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('username', this.state.username);
            window.location.reload();
            console.log(response.data);
        } catch (error) {
            this.setState({
                errors: error.response.data
            });
            console.log(error);
        }
    }


    render() {
        return (
            <>
                Login
                <form onSubmit={this.handleSubmit}>
                    <label>
                        Username:
                        <input name="username" type="text" value={this.state.username} onChange={this.handleChange} />
                    </label>
                    {this.state.errors && this.state.errors.username}
                    <br />
                    <label>
                        Password:
                        <input name="password" type="password" value={this.state.password}
                            onChange={this.handleChange} />
                    </label>
                    {this.state.errors && this.state.errors.password}
                    <br />
                    <input type="submit" value="Submit" />
                </form>
                <p>{this.state.testing}</p>
                {this.state.isLoggedIn ? (
                    <Navigate replace to={routes.STATE_OPTION_LIST} />
                ) : null
                }
            </>
        )
    }

}

export default Login;