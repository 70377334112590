import React, { Component } from "react";
import ButtonComponent from "../buttons/ButtonComponent";
import apiConfig from "../../ApiConfig";

class Logout extends Component {
    constructor(props) {
        super(props);

        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        try {
            apiConfig.defaults.headers['Authorization'] = null;
            localStorage.clear();
            window.location.reload();
            console.log('Done');
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        return (
            <>
                <ButtonComponent onClick={this.handleLogout} label="Logout" />
            </>
        )
    }
}

export default Logout;