import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import routes from "./routes";
import NavBar from "./components/navbar/NavBar";
import Login from "./components/auth/Login";
import StageList from "./components/stages/StageList";
import TruckList from "./components/trucks/TruckList";
import TruckDetail from "./components/trucks/TruckDetail";
import RouteDetail from "./components/routes/RouteDetail";
import WasteDetail from "./components/wastes/WasteDetail";
import WasteList from "./components/wastes/WasteList";
import MaintenanceList from "./components/profiles/MaintenanceList";
import NfcReader from "./components/rfid/Rfid";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path={routes.HOME} element="" />
        <Route path={routes.LOGIN} element={<Login />} />
        <Route path={routes.STAGE_LIST} element={<StageList />} />
        <Route path={routes.TRUCK_LIST} element={<TruckList />} />
        <Route path={routes.TRUCK_DETAIL} element={<TruckDetail />} />
        <Route path={routes.ROUTE_DETAIL} element={<RouteDetail />} />
        <Route path={routes.WASTE_LIST} element={<WasteList />} />
        <Route path={routes.WASTE_DETAIL} element={<WasteDetail />} />
        <Route path={routes.MAINTENANCE_LIST} element={<MaintenanceList />} />
        <Route path={routes.RFID_READER} element={<NfcReader />} />
      </Routes>
    </Router>
  );
}

export default App;
