import React, { useEffect } from 'react';

const NfcReader = () => {
  useEffect(() => {
    const readRFID = async () => {
      try {
        if ('NDEFReader' in window) {
          const ndef = new window.NDEFReader();
          await ndef.scan();

          ndef.onreading = event => {
            const uid = event.serialNumber; // Capture the UID
            alert("Tag UID: " + uid);
            // Redirect back to WhatsApp with the UID as a pre-filled message
            window.location.href = `https://wa.me/?text=Tag UID: ${uid}`;
          };
        } else {
          alert("NFC is not supported on this device.");
        }
      } catch (error) {
        console.error("Error reading NFC tag:", error);
        alert("Failed to read the tag. Please try again.");
      }
    };

    readRFID();
  }, []);

  return (
    <div>
      <h1>Scan your RFID Tag</h1>
      <p>Place your tag near the device to read the UID.</p>
    </div>
  );
};

export default NfcReader;