import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";
import routes from "../../routes";
import * as XLSX from 'xlsx';

const WasteList = () => {
    const [wastes, setWastes] = useState([]);
    const [filteredWastes, setFilteredWastes] = useState([]);
    const [filterDate, setFilterDate] = useState("");

    useEffect(() => {
        fetchWastes();
    }, []);

    const fetchWastes = async () => {
        try {
            const response = await apiConfig.get('/api/wastes/');
            setWastes(response.data);
            console.log('Stages fetched correctly');
        } catch (error) {
            console.error('Error fetching stages', error);
        }
    };

    const formatTime = (datetime) => {
        const date = new Date(datetime);
        return date.toTimeString().slice(0, 5); // Extract HH:MM
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        setFilterDate(selectedDate);

        const filtered = wastes.filter(truck => {
            const truckDate = truck.datetime_created.split('T')[0]; // Get the date part from datetime_created
            return truckDate === selectedDate;
        });

        setFilteredWastes(filtered);
    };

    const downloadExcel = () => {
        const tableData = filteredWastes.map(waste => ({
            ID: waste.id,
            Cliente: waste.route.client,
            HR: waste.route.bravo_code,
            Producto: waste.waste_type ? waste.waste_type.name : null,
            PesoNeto: waste.weight,
            HoraIngreso: formatTime(waste.datetime_created),
            Patente: waste.route.truck.plate,
            Estacion: waste.stage.name,
        }));

        const worksheet = XLSX.utils.json_to_sheet(tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Wastes");
        
        XLSX.writeFile(workbook, `Wastes_${filterDate || 'All'}.xlsx`);
    };

    return (
        <div>
            <input 
                type="date" 
                value={filterDate}
                onChange={handleDateChange} 
                placeholder="Filter by date"
            />
            <button onClick={downloadExcel}>Download Excel</button>
            {filteredWastes.length > 0 ?
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Cliente</th>
                            <th>HR</th>
                            <th>Producto</th>
                            <th>Peso Neto</th>
                            <th>Hora Ingreso</th>
                            <th>Patente</th>
                            <th>Estación</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredWastes.map((waste) => (
                            <tr key={waste.id}>
                                <td><a href={`${routes.WASTE_LIST}/${waste.id}`}>{waste.id}</a></td>
                                <td>{waste.route.client}</td>
                                <td>{waste.route.bravo_code}</td>
                                <td>{waste.waste_type && waste.waste_type.name}</td>
                                <td>{waste.weight}</td>
                                <td>{formatTime(waste.datetime_created)}</td>
                                <td>{waste.route.truck.plate}</td>
                                <td>{waste.stage.name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                :
                <p>No hay camiones...</p>
            }
        </div >
    );
};

export default WasteList;