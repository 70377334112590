import axios from "axios";

import routes from "./routes";


const apiConfig = axios.create({
  baseURL: `${routes.BACKEND}/`,
  timeout: 100000,
  headers: {
    'Authorization': localStorage.getItem('access_token'),
    'Content-Type': 'application/json',
    'accept': 'application/json',
    'ngrok-skip-browser-warning': 'lo'
  }
});

export default apiConfig;