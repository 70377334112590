const routes = {
    // BACKEND: 'https://192.168.3.112:8000',
    // BACKEND: 'http://localhost:8000',
    BACKEND: 'https://bravo-operations.zapto.org',

    HOME: '/',
    LOGIN: '/login',

    STAGE_LIST: '/stages',

    TRUCK_LIST: '/trucks',
    TRUCK_DETAIL: '/trucks/:id',

    ROUTE_LIST: '/routes',
    ROUTE_DETAIL: '/routes/:id',
    
    WASTE_LIST: '/wastes',
    WASTE_DETAIL: '/wastes/:id',

    MAINTENANCE_LIST: '/maintenances',

    RFID_READER: '/rfid_reader',
};

export default routes;