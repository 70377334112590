import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";
import routes from "../../routes";

const TruckList = () => {
    const [trucks, setTrucks] = useState([]);
    const [filteredTrucks, setFilteredTrucks] = useState([]);
    const [filterDate, setFilterDate] = useState("");

    useEffect(() => {
        fetchTrucks();
    }, []);

    const fetchTrucks = async () => {
        try {
            const response = await apiConfig.get('/api/trucks/');
            setTrucks(response.data);
            console.log('Stages fetched correctly');
        } catch (error) {
            console.error('Error fetching stages', error);
        }
    };

    const formatTime = (datetime) => {
        const date = new Date(datetime);
        return date.toTimeString().slice(0, 5); // Extract HH:MM
    };

    const handleDateChange = (event) => {
        const selectedDate = event.target.value;
        setFilterDate(selectedDate);

        const filtered = trucks.filter(truck => {
            const truckDate = truck.datetime_created.split('T')[0]; // Get the date part from datetime_created
            return truckDate === selectedDate;
        });

        setFilteredTrucks(filtered);
    };

    return (
        <div>
            <input 
                type="date" 
                value={filterDate}
                onChange={handleDateChange} 
                placeholder="Filter by date"
            />
            {filteredTrucks.length > 0 ?
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Patente</th>
                            <th>Hora</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTrucks.map((truck) => (
                            <tr key={truck.id}>
                                <td><a href={`${routes.TRUCK_LIST}/${truck.id}`}>{truck.id}</a></td>
                                <td>{truck.plate}</td>
                                <td>{formatTime(truck.datetime_created)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                :
                <p>No hay camiones...</p>
            }
        </div >
    );
};

export default TruckList;