import { useEffect, useState } from "react";
import apiConfig from "../../ApiConfig";
import { useParams } from "react-router-dom";

const WasteDetail = () => {
    const { id } = useParams();
    const [waste, setWaste] = useState(null);

    useEffect(() => {
        fetchWaste();
    }, []);

    const fetchWaste = async () => {
        try {
            const response = await apiConfig.get(`/api/wastes/${id}/`);
            setWaste(response.data);
            console.log('Stages fetched correctly');
        } catch (error) {
            console.error('Error fetching stages', error);
        }
    };

    useEffect(() => {
        const interval = setInterval(fetchWaste, 1000); // Poll every 5 seconds
        return () => clearInterval(interval); // Clean up on unmount
    }, []);

    const formatTime = (datetime) => {
        const date = new Date(datetime);
        return date.toTimeString().slice(0, 5); // Extract HH:MM
    };

    return (
        <div>
            <h1>{waste && waste.id}</h1>
            {waste && waste.waste_checkpoints && waste.waste_checkpoints.length > 0 ?
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Hora</th>
                            <th>Estación</th>
                            <th>Descripción</th>
                        </tr>
                    </thead>
                    <tbody>
                        {waste.waste_checkpoints.map((waste_checkpoint) => (
                            <tr key={waste_checkpoint.id}>
                                <td>{waste_checkpoint.id}</td>
                                <td>{formatTime(waste_checkpoint.datetime_created)}</td>
                                <td>{waste_checkpoint.stage.name}</td>
                                <td>{waste_checkpoint.description}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                :
                <p>No hay camiones...</p>
            }
        </div >
    );
};

export default WasteDetail;